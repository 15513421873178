import React, { useContext, useState } from "react";
import styles from "./style.module.scss";
import { DutyPhone } from "@models/dutyPhone";
import GroupHeader from "../../Common/GroupHeader";
import classNames from "classnames";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import ActiveCell from "../../Common/ActiveCell";
import { Table } from "@frontend/assaia-ui";

interface Props {
  group: string;
}

import { IntlShape, useIntl } from "react-intl";

const getColumns = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      defaultMessage: "Phone",
      description: "Duty phone table column title",
    }),
    render: (v: DutyPhone) => v.phone,
    width: "40%",
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Description",
      description: "Duty phone table column title",
    }),
    render: (v: DutyPhone) => v.description,
    width: "50%",
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Active",
      description: "Duty phone table column title",
    }),
    getCellClass: (v: DutyPhone) =>
      classNames(styles.bold, { green: v.active }),
    render: (v: DutyPhone) => <ActiveCell active={v.active} />,
    width: "10%",
  },
];

const DutyPhonesGroup: React.FC<Props> = ({ group }) => {
  const {
    createDutyPhone,
    deleteDutyPhones,
    editDutyPhone,
    toggleDutyPhonesActive,
    groupedDutyPhones,
    highlightItemId,
    setHighlightItem,
  } = useContext(HomeContext).dutyPhonesStore;
  const intl = useIntl();

  const dutyPhones = groupedDutyPhones[group] || [];

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);

  const onDelete = () => {
    deleteDutyPhones(selectedNotificationsIds);
    setSelectedNotificationsIds([]);
  };

  const onToggleActive = (val: boolean) => {
    toggleDutyPhonesActive(selectedNotificationsIds, val);
    setSelectedNotificationsIds([]);
  };

  return (
    <section className={styles.group}>
      <GroupHeader
        singularLabel="number"
        pluralLabel="numbers"
        group={group}
        activeItems={dutyPhones
          .filter(({ active }) => active)
          .map(({ id }) => id)}
        selectedItems={selectedNotificationsIds}
        onAddItem={() => createDutyPhone(group)}
        onDelete={onDelete}
        onToggleActive={onToggleActive}
      />

      <Table
        columns={getColumns(intl)}
        getRowKey={(v) => v.id}
        items={dutyPhones}
        selectedItems={selectedNotificationsIds}
        onSelect={(ids) => setSelectedNotificationsIds(ids)}
        onRowClick={(v: DutyPhone) => editDutyPhone(v)}
        getRowClass={(v: DutyPhone) => (v.active ? "" : "disabled")}
        highlightItemId={highlightItemId}
        onHighlightEnd={() => setHighlightItem(null)}
      />
    </section>
  );
};

export default observer(DutyPhonesGroup);
