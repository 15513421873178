import { makeAutoObservable, runInAction } from "mobx";
import { cloneDeep, merge } from "lodash";
import {
  MergedTurnaroundAttribute,
  severityKeys,
  TurnaroundAttribute,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { confirmModal } from "@services/syncedModal";
import { IIncidentModalStore } from "./index.types";
import { IHomeStore } from "../HomeStore/index.types";
import { FiltersStore } from "../FiltersStore";
import { isDev } from "@services/constants";
import { createLogger } from "@services/createLogger";

export type { IIncidentModalStore } from "./index.types";

const logger = createLogger("[IncidentModalStore]");

const getDefaultAttribute = (): MergedTurnaroundAttribute => ({
  timeShift: 0,
  type: "event",
  objectName: "",
  eventName: "",
  parameterName: "eobt",
  objectType: null,
  objectPosition: null,
});

export default class IncidentModalStore implements IIncidentModalStore {
  private readonly _incidentConfig: UnifiedIncidentConfig = {
    id: "",
    active: true,
    customText: null,
    incidentType: "unified",
    data: {
      incidentType: "unified",
      type: "unified",
      detectedAttributes: [
        {
          type: "event",
          objectName: "",
          eventName: "",
          objectPosition: null,
          objectType: null,
        },
      ],
      endRangeBoundary: null,
      firingPointInTime: null,
      startRangeBoundary: {
        timeShift: 0,
        turnaroundAttribute: {
          type: "event",
          objectName: "",
          eventName: "",
          objectType: null,
          objectPosition: null,
        },
      },
      trigger: "happened",
    },
    excludedAircraftTypes: [],
    requiredAircraftTypes: [],
    requiredAirlineIcaoAndIata: [],
    excludedAirlineIcaoAndIata: [],
    excludedStands: [],
    requiredStands: [],
    group: "",
    inboundFlightStatus: null,
    outboundFlightStatus: null,
    requiredTurnaroundLength: null,
    severity: "HIGH",
  };
  private readonly _filtersStore: FiltersStore;

  selectedAttributeKey:
    | "attributeSettings"
    | "startRangeBoundary"
    | "firingPointInTime"
    | "endRangeBoundary" = "startRangeBoundary";
  attributeSettings: Record<
    IncidentModalStore["selectedAttributeKey"],
    MergedTurnaroundAttribute
  > = {
    attributeSettings: getDefaultAttribute(),
    startRangeBoundary: getDefaultAttribute(),
    firingPointInTime: getDefaultAttribute(),
    endRangeBoundary: getDefaultAttribute(),
  };

  modalScreen: "main" | "attribute" | "timing" | "timingOptions" | "filters" =
    "main";

  screenShown: Record<IncidentModalStore["selectedAttributeKey"], boolean> = {
    attributeSettings: false,
    startRangeBoundary: false,
    firingPointInTime: false,
    endRangeBoundary: false,
  };

  isSubmitting = false;

  constructor(
    private _home: IHomeStore,
    {
      newAlertGroup,
      existingIncidentConfig,
    }: {
      newAlertGroup: string | null;
      existingIncidentConfig?: UnifiedIncidentConfig | null;
    },
  ) {
    if (existingIncidentConfig) {
      this._incidentConfig = cloneDeep(existingIncidentConfig);
    } else if (newAlertGroup) {
      this._incidentConfig.group = newAlertGroup;
    }

    const config = this._incidentConfig;
    const attribute = config.data.detectedAttributes[0];
    const { startRangeBoundary, firingPointInTime, endRangeBoundary } =
      config.data;

    this._initAttribute("attributeSettings", attribute);
    this._initAttribute(
      "startRangeBoundary",
      startRangeBoundary.turnaroundAttribute,
      startRangeBoundary.timeShift,
    );
    firingPointInTime &&
      this._initAttribute(
        "firingPointInTime",
        firingPointInTime.turnaroundAttribute,
        firingPointInTime.timeShift,
      );
    endRangeBoundary &&
      this._initAttribute(
        "endRangeBoundary",
        endRangeBoundary.turnaroundAttribute,
        endRangeBoundary.timeShift,
      );

    const {
      requiredAircraftTypes,
      excludedAircraftTypes,
      requiredStands,
      excludedStands,
      inboundFlightStatus,
      outboundFlightStatus,
      requiredTurnaroundLength,
      requiredAirlineIcaoAndIata,
      excludedAirlineIcaoAndIata,
    } = config;

    this._filtersStore = new FiltersStore(
      _home,
      {
        requiredAircraftTypes,
        excludedAircraftTypes,
        requiredStands,
        excludedStands,
        inboundFlightStatus,
        outboundFlightStatus,
        requiredTurnaroundLength,
        requiredAirlineIcaoAndIata,
        excludedAirlineIcaoAndIata,
      },
      {
        showStandFilters: true,
        showAirlineFilters: true,
      },
    );

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get filtersStore() {
    return this._filtersStore;
  }

  get active() {
    return this._incidentConfig.active;
  }

  get customText() {
    return this._incidentConfig.customText || "";
  }

  get severity() {
    return this._incidentConfig.severity;
  }

  get trigger() {
    return this._incidentConfig.data.trigger;
  }

  get timeShift() {
    return this.selectedAttribute.timeShift / 60;
  }

  get home() {
    return this._home;
  }

  get detectedAttribute() {
    return this._incidentConfig.data.detectedAttributes[0];
  }

  get selectedAttribute() {
    return this.attributeSettings[this.selectedAttributeKey];
  }

  get isNewIncidentConfig() {
    return !this._incidentConfig.id;
  }

  closeModal() {
    this.home.notificationsStore.editNotification();
  }

  setAttributeSettings(v: Partial<MergedTurnaroundAttribute>) {
    const target = this.selectedAttribute;
    this._setTurnaroundAttribute(target, v);
  }

  resetAttributeSettings() {
    const target = this.selectedAttribute;
    this._setTurnaroundAttribute(target, getDefaultAttribute());
  }

  setTimingTimeShift(minutes: string | number) {
    const parsed =
      typeof minutes === "number" ? minutes : parseFloat(minutes) || 0;
    console.log("setTimingTimeShift", parsed);
    this.setAttributeSettings({ timeShift: parsed * 60 });
  }

  setSelectedAttribute(v: IncidentModalStore["selectedAttributeKey"]) {
    this.selectedAttributeKey = v;
  }

  markScreenAsShown(v: IncidentModalStore["selectedAttributeKey"]) {
    if (v === "attributeSettings") {
      this.screenShown.attributeSettings = true;
    } else {
      this.screenShown.startRangeBoundary = true;
      this.screenShown.firingPointInTime = true;
      this.screenShown.endRangeBoundary = true;
    }
  }

  getInvalidFields(
    v: IncidentModalStore["selectedAttributeKey"],
    ignoreShown = false,
  ) {
    if (!ignoreShown) {
      if (!this.screenShown[v]) {
        return [];
      }
    }
    return this._getAttributeInvalidKeys(this.attributeSettings[v]);
  }

  setDetectedAttribute(v: TurnaroundAttribute) {
    this._incidentConfig.data.detectedAttributes = [v];
  }

  setScreen(v: IncidentModalStore["modalScreen"]) {
    if (v === "attribute") {
      this.setSelectedAttribute("attributeSettings");
    }
    this.modalScreen = v;
  }

  toggleActive() {
    this._incidentConfig.active = !this.active;
  }

  setCustomText(v: string) {
    this._incidentConfig.customText = v ? v : null;
  }

  setTrigger(v: IncidentModalStore["trigger"]) {
    this._incidentConfig.data.trigger = v;
  }

  toggleSeverity() {
    let index = severityKeys.indexOf(this.severity) + 1;
    if (index > severityKeys.length - 1) {
      index = 0;
    }

    this._incidentConfig.severity = severityKeys[index];
  }

  deleteNotification = async () => {
    if (this.isSubmitting) {
      return;
    }

    const { id } = this._incidentConfig;
    if (!id) {
      return;
    }

    const confirmed = await confirmModal();
    if (!confirmed) {
      return;
    }

    runInAction(() => (this.isSubmitting = true));

    try {
      await this.home.notificationsStore.deleteNotifications([id]);
      this.closeModal();
    } catch (error) {
      if (isDev) {
        console.error(
          "An error occurred while executing `deleteNotification` method of the `IncidentModalStore`:",
          error,
        );
      }
    }

    runInAction(() => (this.isSubmitting = false));
  };

  async submit(this: IncidentModalStore) {
    if (!this.customText || this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this._incidentConfig.data.detectedAttributes[0] =
      this._convertToTurnaroundAttribute(
        this.attributeSettings.attributeSettings,
      );

    const startRangeBoundary = this.attributeSettings.startRangeBoundary;
    this._incidentConfig.data.startRangeBoundary = {
      timeShift: startRangeBoundary.timeShift,
      turnaroundAttribute:
        this._convertToTurnaroundAttribute(startRangeBoundary),
    };

    (["firingPointInTime", "endRangeBoundary"] as const).forEach((key) => {
      const data = this.attributeSettings[key];
      const { objectName, eventName, type, parameterName, timeShift } = data;
      let valid: boolean;

      if (type === "event") {
        valid = !!(objectName && eventName);
      } else {
        valid = !!parameterName;
      }

      this._incidentConfig.data[key] = valid
        ? {
            timeShift,
            turnaroundAttribute: this._convertToTurnaroundAttribute(data),
          }
        : null;
    });

    const { requiredAircraftTypes, excludedAircraftTypes } =
      this._filtersStore.aircraftFilters;
    this._incidentConfig.requiredAircraftTypes = requiredAircraftTypes;
    this._incidentConfig.excludedAircraftTypes = excludedAircraftTypes;

    const { requiredStands, excludedStands } = this._filtersStore.standFilters;
    this._incidentConfig.requiredStands = requiredStands;
    this._incidentConfig.excludedStands = excludedStands;

    const { requiredAirlineIcaoAndIata, excludedAirlineIcaoAndIata } =
      this._filtersStore.airlineFilters;

    logger.log(
      "airlineFilters",
      cloneDeep({ requiredAirlineIcaoAndIata, excludedAirlineIcaoAndIata }),
    );

    this._incidentConfig.requiredAirlineIcaoAndIata =
      requiredAirlineIcaoAndIata;
    this._incidentConfig.excludedAirlineIcaoAndIata =
      excludedAirlineIcaoAndIata;

    const {
      requiredTurnaroundLength,
      inboundFlightStatus,
      outboundFlightStatus,
    } = this._filtersStore;
    this._incidentConfig.requiredTurnaroundLength = requiredTurnaroundLength;
    this._incidentConfig.inboundFlightStatus = inboundFlightStatus;
    this._incidentConfig.outboundFlightStatus = outboundFlightStatus;

    const { id, ...rest } = this._incidentConfig;

    logger.log("airlineFilters", cloneDeep(this._incidentConfig));

    try {
      await this.home.notificationsStore.saveNotification(rest, id);
      this.closeModal();
    } catch (error) {
      if (isDev) {
        console.error(
          "An error occurred while executing `submit` method of the `IncidentModalStore`:",
          error,
        );
      }
    }

    runInAction(() => (this.isSubmitting = false));
  }

  private _initAttribute(
    key: IncidentModalStore["selectedAttributeKey"],
    attribute: TurnaroundAttribute,
    timeShift = 0,
  ) {
    if (attribute.type === "event") {
      this.attributeSettings[key] = {
        ...attribute,
        parameterName: "eobt",
        timeShift,
      };
    } else if (attribute.type === "turnaround_param") {
      this.attributeSettings[key] = {
        ...attribute,
        objectName: "",
        eventName: "",
        objectPosition: null,
        objectType: null,
        timeShift,
      };
    }
  }

  private _getAttributeInvalidKeys(
    attribute: MergedTurnaroundAttribute,
  ): (keyof MergedTurnaroundAttribute)[] {
    const result: (keyof MergedTurnaroundAttribute)[] = [];
    if (attribute.type === "event") {
      !attribute.objectName && result.push("objectName");
      !attribute.eventName && result.push("eventName");
    } else {
      !attribute.parameterName && result.push("parameterName");
    }
    return result;
  }

  private _setTurnaroundAttribute(
    target: MergedTurnaroundAttribute,
    v: Partial<MergedTurnaroundAttribute>,
  ) {
    merge(target, v);
  }

  private _convertToTurnaroundAttribute({
    objectName,
    eventName,
    objectType,
    objectPosition,
    type,
    parameterName,
  }: MergedTurnaroundAttribute): TurnaroundAttribute {
    return type === "event"
      ? {
          type,
          eventName,
          objectName,
          objectPosition,
          objectType,
        }
      : {
          type,
          parameterName,
        };
  }
}
