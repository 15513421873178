import React, { useContext, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import UsersPage from "../../UsersPage";
import Header from "../Header";
import "./style.scss";
import UIAlertModal from "../UIAlertModal";
import NotificationsPage from "../../NotificationsPage";
import { URLS } from "@services/router";
import DutyPhonesPage from "../../DutyPhonesPage";
import { observer } from "mobx-react";
import { HomeContext, RootContext } from "@services/react";
import PtsDetailsPage from "../../PtsDetailsPage";
import PtsListPage from "../../PtsListPage";
import PtsMainModal from "../../PtsMainModal";
import { OIDCContext } from "@frontend/assaia-ui";
import Spinner from "../../Common/Spinner";
import { getConfig } from "@di";
import { IntlProvider } from "@components/App/IntlProvider";

const Home = () => {
  const { user, logout } = useContext(OIDCContext);
  const {
    initHomeStore,
    disposeHomeStore,
    authStore: { setUser, setLogoutCallback },
  } = useContext(RootContext);

  const homeStore = useMemo(() => {
    setLogoutCallback(logout);
    setUser(user);
    return initHomeStore();
  }, [initHomeStore]);

  useEffect(() => {
    return () => disposeHomeStore();
  }, []);

  const {
    ptsStore: { ptsData },
    uiAlert: notification,
    ready,
  } = homeStore;

  if (!ready) {
    return <Spinner />;
  }

  const {
    profile: { is_notification_admin, is_user_admin, is_pts_admin },
  } = user;

  const defaultUrl = is_user_admin
    ? URLS.USERS
    : is_notification_admin
      ? URLS.NOTIFICATIONS
      : is_pts_admin
        ? URLS.PTS
        : URLS.USERS;

  const { locales, defaultLocale } = getConfig();
  const { enableDutyPhones, enablePts } = getConfig();
  return (
    <IntlProvider value={{ locales, defaultLocale }}>
      <HomeContext.Provider value={homeStore}>
        <div className={"home"}>
          <Header />
          <Switch>
            {is_user_admin && (
              <Route
                path={`${URLS.USERS}/:userId?`}
                component={UsersPage}
                exact
              />
            )}

            {is_notification_admin && (
              <Route
                path={URLS.NOTIFICATIONS}
                component={NotificationsPage}
                exact
              />
            )}

            {is_user_admin && enableDutyPhones && (
              <Route path={URLS.DUTY_PHONES} component={DutyPhonesPage} exact />
            )}

            {is_pts_admin && enablePts && (
              <Route path={URLS.PTS} component={PtsListPage} exact />
            )}
            {is_pts_admin && enablePts && (
              <Route
                path={`${URLS.PTS}/:ptsId`}
                component={PtsDetailsPage}
                exact
              />
            )}
            <Redirect to={defaultUrl} />
          </Switch>
          {notification && <UIAlertModal />}
          {ptsData && <PtsMainModal ptsData={ptsData} />}
        </div>
      </HomeContext.Provider>
    </IntlProvider>
  );
};
export default observer(Home);
