import { notificationsApiClient } from "./client";
import { snakeCaseKeys } from "./data";
import { NotificationsApi } from "./types";
import AircraftGroup from "../models/aircraftGroup";
import { parseAircraftGroups, parseIncidentConfig } from "./apiParse";
import {
  IncidentConfig,
  NewIncidentConfig,
  SafetyAlertIncidentConfig,
  SafetyEventIncidentConfig,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import di from "@di";
import { cloneDeep } from "lodash";

export const notificationsApiUrl = () => {
  const { authority } = di.resolve("openIdConfig");
  return (new URL(authority).origin + "/api/").replace("id.", "");
};

const snakeCaseKeysIncidentConfig = ({
  requiredAirlineIcaoAndIata,
  excludedAirlineIcaoAndIata,
  ...v
}: Partial<IncidentConfig | NewIncidentConfig>) => {
  const result = snakeCaseKeys(v);

  result.required_airlines = requiredAirlineIcaoAndIata;
  result.excluded_airlines = excludedAirlineIcaoAndIata;

  result.data.strategy = result.data.type;

  if ("incident_type" in result.data) {
    delete result.data.incident_type;
  }
  if ("type" in result.data) {
    result.data.strategy = result.data.type;
    delete result.data.type;
  }

  if ("detected_attributes" in result.data) {
    result.data.detected_attributes = result.data.detected_attributes.map(
      (v: any) => snakeCaseKeys(v),
    );
  }

  console.log(
    "snakeCaseKeysIncidentConfig",
    cloneDeep({
      requiredAirlineIcaoAndIata,
      excludedAirlineIcaoAndIata,
      v,
      result,
    }),
  );

  return result;
};

const getNotifications = () =>
  notificationsApiClient
    .get<IncidentConfig[]>(notificationsApiUrl() + "incident_configs/")
    .then((r) => {
      const rawConfigs = r.data;
      const result: {
        unifiedConfigs: UnifiedIncidentConfig[];
        safetyAlertConfigs: SafetyAlertIncidentConfig[];
        safetyEventConfigs: SafetyEventIncidentConfig[];
      } = {
        unifiedConfigs: [],
        safetyAlertConfigs: [],
        safetyEventConfigs: [],
      };

      rawConfigs.forEach((raw) => {
        const parsedData = parseIncidentConfig(raw);
        const data = parsedData.data;
        if (data.incidentType === "unified") {
          result.unifiedConfigs.push({
            ...parsedData,
            data,
          });
        } else if (data.incidentType === "safety-alert") {
          result.safetyAlertConfigs.push({
            ...parsedData,
            data,
          });
        } else if (data.incidentType === "safety-event") {
          result.safetyEventConfigs.push({
            ...parsedData,
            data,
          });
        }
      });

      return result;
    });

const saveNewNotification = (data: NewIncidentConfig) =>
  notificationsApiClient
    .post<IncidentConfig>(
      notificationsApiUrl() + "incident_configs/",
      snakeCaseKeysIncidentConfig(data),
    )
    .then((res) => parseIncidentConfig(res.data));

const patchNotification = (id: string, data: Partial<NewIncidentConfig>) =>
  notificationsApiClient
    .patch<IncidentConfig>(
      notificationsApiUrl() + "incident_configs/" + id,
      snakeCaseKeysIncidentConfig(data),
    )
    .then((res) => parseIncidentConfig(res.data));

const deleteNotifications = (ids: string[]) => {
  const query = ids.map((id) => "id=" + id).join("&");
  return notificationsApiClient.delete(
    notificationsApiUrl() + "incident_configs/?" + query,
  );
};

const toggleNotificationsActive = (ids: string[], active: boolean) => {
  const query = ids.map((id) => "id=" + id).join("&");
  return notificationsApiClient.patch(
    notificationsApiUrl() + "incident_configs/?" + query,
    { active },
  );
};

const getStands = (): Promise<string[]> => {
  return notificationsApiClient
    .get(notificationsApiUrl() + "stands/")
    .then((r) => r.data.map((s: any) => s.id));
};

const getAircrafts = (): Promise<AircraftGroup[]> => {
  return notificationsApiClient
    .get(notificationsApiUrl() + "flights/aircraft_groups")
    .then((r) => parseAircraftGroups(r.data));
};

const getAirlines = () => {
  return notificationsApiClient
    .get(notificationsApiUrl() + "flights/airlines")
    .then((r) =>
      r.data.map((v: any) => ({
        ...v,
        id: v.id.toString(),
      })),
    );
};

const notificationsApi: NotificationsApi = {
  getNotifications,
  saveNewNotification,
  patchNotification,
  deleteNotifications,
  toggleNotificationsActive,
  getStands,
  getAircrafts,
  getAirlines,
};

export default notificationsApi;
