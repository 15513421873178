import { defineMessages } from "react-intl";

export const TURN_LENGTH_LABELS = defineMessages({
  SHORT: {
    defaultMessage: "Short (<140 minutes)",
    description: "turn length label",
  },
  LONG: {
    defaultMessage: "Long (>140 minutes)",
    description: "turn length label",
  },
});

export const TURN_STATUS_LABELS = defineMessages({
  TOW_ON: {
    defaultMessage: "Tow on flights only",
    description: "turn status label",
  },
  TOW_OFF: {
    defaultMessage: "Tow off flights only",
    description: "turn status label",
  },
  INBOUNDS_ONLY: {
    defaultMessage: "Tow off and full turn flights only",
    description: "turn status label",
  },
  FULL_FLIGHTS_ONLY: {
    defaultMessage: "Full turn flights only",
    description: "turn status label",
  },
});

export const notificationTriggerLabels = defineMessages({
  happened: {
    description: "notification trigger",
    defaultMessage: "happened",
  },
  not_happened: {
    description: "notification trigger",
    defaultMessage: "did not happen",
  },
});
