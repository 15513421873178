import React, { ComponentProps } from "react";
import {
  ModalLabel,
  ModalHeader,
  ModalCell,
  TertiaryButton,
  ModalDropdownSelectOption,
  ModalDropdownScreen,
  ModalCombobox,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import { ReactComponent as DropdownSVG } from "@assets/dropdown-icon.svg";
import styles from "./style.module.scss";
import ModalSmallDropdown from "../ModalSmallDropdown";
import {
  FULL_FLIGHTS_ONLY,
  INBOUNDS_ONLY,
  TOW_OFF,
  TOW_ON,
} from "@models/incidentConfig";
import { FiltersStore } from "@stores/FiltersStore";
import { ModalSpacer } from "../ModalSpacer";
import { FormattedMessage, useIntl } from "react-intl";
import { TURN_LENGTH_LABELS, TURN_STATUS_LABELS } from "@i18n/messages";

interface Props {
  onClose: () => void;
  onBack: () => void;
  filtersStore: FiltersStore;
}

const ModalFilterScreen = ({ onClose, onBack, filtersStore }: Props) => {
  const {
    setAircraftsFilterType,
    setStandsFilterType,
    toggleAircraftGroup,
    toggleAircraft,
    toggleAirline,
    toggleStand,
    selectedStands,
    selectedAircraftTypes,
    aircraftFilterType,
    standFilterType,
    aircrafts,
    stands,
    flightStatus,
    turnaroundLength,
    setFlightStatus,
    setTurnaroundLength,
    showStandFilters,
    showAirlineFilters,
    allAirlines,
    selectedAirlineIds,
    airlineFilterType,
    setAirlineFilterType,
  } = filtersStore;
  const intl = useIntl();

  const lengthsOptions: ModalDropdownSelectOption<"SHORT" | "LONG">[] = [
    { id: "SHORT", title: intl.formatMessage(TURN_LENGTH_LABELS.SHORT) },
    { id: "LONG", title: intl.formatMessage(TURN_LENGTH_LABELS.LONG) },
  ];

  const statuses: ModalDropdownSelectOption<typeof flightStatus>[] = [
    { id: TOW_ON, title: intl.formatMessage(TURN_STATUS_LABELS.TOW_ON) },
    { id: TOW_OFF, title: intl.formatMessage(TURN_STATUS_LABELS.TOW_OFF) },
    {
      id: INBOUNDS_ONLY,
      title: intl.formatMessage(TURN_STATUS_LABELS.INBOUNDS_ONLY),
    },
    {
      id: FULL_FLIGHTS_ONLY,
      title: intl.formatMessage(TURN_STATUS_LABELS.FULL_FLIGHTS_ONLY),
    },
  ];

  const aircraftData: ComponentProps<typeof ModalCombobox>["groupedOptions"] =
    {};
  aircrafts.forEach(({ groupName, groupTypes }) => {
    aircraftData[groupName] = groupTypes.map((v) => ({
      id: v,
      title: v,
    }));
  });

  const standsOptions: ComponentProps<typeof ModalCombobox>["options"] =
    stands.map((standId) => ({
      id: standId,
      title: standId,
    }));

  const getSmallDropdown = (
    v: "includes" | "excludes",
    onChange: (v: "includes" | "excludes") => void,
  ) => (
    <ModalSmallDropdown
      trigger={
        <TertiaryButton hoverable={true}>
          <span className={styles.text}>
            {v === "includes"
              ? intl.formatMessage({
                  defaultMessage: "Includes",
                  description: "Modal filter",
                })
              : intl.formatMessage({
                  defaultMessage: "Excludes",
                  description: "Modal filter",
                })}
          </span>
          <div className={styles.icon}>
            <DropdownSVG />
          </div>
        </TertiaryButton>
      }
    >
      <ModalCell
        onClick={() => onChange("includes")}
        className={styles.cell}
        title={intl.formatMessage({
          defaultMessage: "Includes",
          description: "Modal filter",
        })}
      />
      <ModalCell
        onClick={() => onChange("excludes")}
        className={styles.cell}
        title={intl.formatMessage({
          defaultMessage: "Excludes",
          description: "Modal filter",
        })}
      />
    </ModalSmallDropdown>
  );

  const airlinesOptions: ComponentProps<typeof ModalCombobox>["options"] =
    allAirlines.map((item) => ({
      id: item.id,
      title: `${item.name} • ${item.iata} • ${item.icao}`,
    }));

  return (
    <>
      <ModalHeader
        onBack={onBack}
        onClose={onClose}
        icon={<FilterSVG />}
        title={intl.formatMessage({
          defaultMessage: "Filters",
          description: "Modal filter",
        })}
        showBorderBottom={true}
      />
      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Turnaround length",
          description: "Modal filter",
        })}
      >
        <ModalDropdownScreen
          title={intl.formatMessage({
            defaultMessage: "Turnaround length",
            description: "Modal filter",
          })}
          value={
            turnaroundLength
              ? intl.formatMessage(TURN_LENGTH_LABELS[turnaroundLength])
              : undefined
          }
          options={lengthsOptions}
          selectedIds={turnaroundLength ? [turnaroundLength] : []}
          emptyOption
          emptyText={intl.formatMessage({
            defaultMessage: "Any",
            description: "Modal filter any turn length",
          })}
          onSelect={([v]) => setTurnaroundLength(v)}
        />
      </ModalLabel>

      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Inbound / outbound",
          description: "Modal filter",
        })}
      >
        <ModalDropdownScreen
          title={intl.formatMessage({
            defaultMessage: "Inbound / outbound",
            description: "Modal filter",
          })}
          value={
            flightStatus
              ? intl.formatMessage(TURN_STATUS_LABELS[flightStatus])
              : undefined
          }
          options={statuses}
          placeholder={intl.formatMessage({
            defaultMessage: "Select value",
            description: "Modal filter",
          })}
          emptyText={intl.formatMessage({
            defaultMessage: "Select value",
            description: "Modal filter",
          })}
          selectedIds={flightStatus ? [flightStatus] : []}
          onSelect={([v]) => setFlightStatus(v)}
          emptyOption
        />
      </ModalLabel>

      <ModalLabel
        title={
          <div className={styles.labelDropdown}>
            <FormattedMessage
              defaultMessage="Aircraft type"
              description="Modal aricraft filter filed title"
            />
            {getSmallDropdown(aircraftFilterType, setAircraftsFilterType)}
          </div>
        }
      >
        <ModalCombobox
          title={intl.formatMessage({
            defaultMessage: "Start typing aircraft type...",
            description: "Modal filter",
          })}
          onGroupSelect={toggleAircraftGroup}
          onValueSelect={toggleAircraft}
          selectedIds={selectedAircraftTypes}
          groupedOptions={aircraftData}
        />
      </ModalLabel>

      {showStandFilters && (
        <ModalLabel
          title={
            <div className={styles.labelDropdown}>
              <FormattedMessage
                defaultMessage="Stand"
                description="Modal stand filter filed title"
              />
              {getSmallDropdown(standFilterType, setStandsFilterType)}
            </div>
          }
        >
          <ModalCombobox
            title={intl.formatMessage({
              defaultMessage: "Start typing stand name...",
              description: "Modal filter",
            })}
            onValueSelect={toggleStand}
            selectedIds={selectedStands}
            options={standsOptions}
          />
        </ModalLabel>
      )}

      {showAirlineFilters && (
        <ModalLabel
          title={
            <div className={styles.labelDropdown}>
              <FormattedMessage
                defaultMessage="Airline"
                description="[Incident Modal Filter Screen] Airline filter field title"
              />
              {getSmallDropdown(airlineFilterType, setAirlineFilterType)}
            </div>
          }
        >
          <ModalCombobox
            title={intl.formatMessage({
              defaultMessage: "Start typing airline, ICAO or IATA...",
              description:
                "[Incident Modal Filter Screen] Airline filter combobox title",
            })}
            onValueSelect={toggleAirline}
            selectedIds={selectedAirlineIds}
            options={airlinesOptions}
          />
        </ModalLabel>
      )}

      <ModalSpacer />
    </>
  );
};

export default observer(ModalFilterScreen);
