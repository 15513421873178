import React, { PropsWithChildren } from "react";
import s from "./style.module.scss";
import { FormattedMessage } from "react-intl";

const RedStar = ({ children }: PropsWithChildren<{}>) => (
  <span>
    {children}&nbsp;
    <span className={s.star}>
      <FormattedMessage defaultMessage="*" description="red star" />
    </span>
  </span>
);

export default RedStar;
