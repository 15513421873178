import { camelCaseKeys } from "./data";
import { IncidentConfig } from "@models/incidentConfig";

export function parseIncidentConfig({
  required_airlines,
  excluded_airlines,
  ...data
}: any): IncidentConfig {
  const config = camelCaseKeys(data) as IncidentConfig;
  config.data.type = data.data.strategy;
  config.data.incidentType = data.incident_type;

  if (!config.requiredAircraftTypes) {
    config.requiredAircraftTypes = [];
  }
  if (!config.excludedAircraftTypes) {
    config.excludedAircraftTypes = [];
  }
  if (!config.requiredStands) {
    config.requiredStands = [];
  }
  if (!config.excludedStands) {
    config.excludedStands = [];
  }

  config.requiredAirlineIcaoAndIata = required_airlines || [];
  config.excludedAirlineIcaoAndIata = excluded_airlines || [];

  if ("detectedAttributes" in config.data) {
    config.data.detectedAttributes = config.data.detectedAttributes.map((v) =>
      camelCaseKeys(v),
    );
  }

  return config;
}

export const parseAircraftGroups = (data: any) =>
  data
    .filter((v: any) => v.group_types && v.group_types.length)
    .map(camelCaseKeys);
