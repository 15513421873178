import { action, makeAutoObservable, runInAction } from "mobx";
import { NotificationsApi } from "@services/types";
import Role from "../../models/role";
import { cloneDeep, keyBy, sortBy } from "lodash";
import {
  customMerge,
  getTimerLabelData,
  getTriggerLabelData,
} from "@services/data";
import { computedFn } from "mobx-utils";
import {
  NewIncidentConfig,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { INotificationsStore } from "./index.types";
import { IHomeStore } from "../HomeStore/index.types";
import { IntlShape } from "react-intl";
import { createLogger } from "@services/createLogger";

const logger = createLogger("[NotificationsStore]");

export default class NotificationsStore implements INotificationsStore {
  notifications: UnifiedIncidentConfig[] = [];
  showNotificationModal = false;
  notificationModalData: UnifiedIncidentConfig | null = null;
  newAlertGroup: string | null = null;
  ready = false;
  groups: Role[] = [];
  searchQuery = "";
  stands: string[] = [];
  highlightItemId: string | null = null;

  constructor(
    private home: IHomeStore,
    private api: NotificationsApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get notificationGroups() {
    return this.home.notificationGroups;
  }

  get aircrafts() {
    return this.home.aircrafts;
  }

  get notificationsTable(): Record<string, UnifiedIncidentConfig | undefined> {
    return keyBy(this.notifications, "id");
  }

  init() {
    const erCb = (er: Error) => {
      this.home.setUIAlert("Error: something went wrong", "error");
      throw er;
    };

    const promises = [
      this.api
        .getStands()
        .then(action((items) => (this.stands = items)))
        .catch(erCb),
      this.home.initAircrafts().catch(erCb),
      this.home.initAirlines().catch(erCb),
      this.api
        .getNotifications()
        .then(action((items) => (this.notifications = items.unifiedConfigs)))
        .catch(erCb),
    ];

    return Promise.all(promises).then(
      action(() => {
        this.ready = true;
      }),
    );
  }

  // TODO dont use intl directly in this function
  //
  /**
   * Memoized
   */
  prepareNotificationsByGroup = computedFn((group: string, intl: IntlShape) => {
    const query = this.searchQuery.toLowerCase().trim();
    const allNotifications = this.notifications;
    let items = sortBy(
      allNotifications.filter((n) => n.group === group),
      "data.objectName",
    );
    if (query.length >= 2) {
      items = items.filter((i) => {
        const triggerLabel = getTriggerLabelData(i, intl);
        const startRangeBoundary = getTimerLabelData(i.data.startRangeBoundary);
        const firingPointInTime = getTimerLabelData(i.data.firingPointInTime);
        const endRangeBoundary = getTimerLabelData(i.data.endRangeBoundary);

        const stringsToCheck = [
          ...triggerLabel.label,
          ...Object.values(triggerLabel).flat(),
          ...Object.values(startRangeBoundary).flat(),
          ...Object.values(firingPointInTime).flat(),
          ...Object.values(endRangeBoundary).flat(),
        ].map((s) => s.toLowerCase());

        const text = (i.customText || "").toLowerCase();
        return (
          stringsToCheck.some((s) => s.includes(query)) || text.includes(query)
        );
      });
    }
    return items;
  });

  editNotification(notification?: NotificationsStore["notificationModalData"]) {
    this.notificationModalData = notification || null;
    this.showNotificationModal = !!notification;
  }

  /**
   * Opens modal
   */
  createNotification(group: string) {
    this.newAlertGroup = group;
    this.showNotificationModal = true;
  }

  saveNotification(data: NewIncidentConfig, targetId?: string) {
    logger.log("saveNotification", cloneDeep(data), targetId);

    const promise = targetId
      ? this.api.patchNotification(targetId, data)
      : this.api.saveNewNotification(data);

    return promise
      .then((item) => {
        this._updateNotification(item);

        this.home.setUIAlert("The changes have been applied");
        this.setHighlightItem(item.id);

        return item;
      })
      .catch((er) => {
        this.home.setUIAlert("Error: changes have not been applied", "error");
        throw er;
      });
  }

  async deleteNotifications(ids: string[]) {
    try {
      await this.api.deleteNotifications(ids);

      runInAction(() => {
        this.notifications = this.notifications.filter(
          (v) => !ids.includes(v.id),
        );
      });

      this.home.setUIAlert("Alert config has been removed");
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  async toggleNotificationsActive(ids: string[], active: boolean) {
    try {
      await this.api.toggleNotificationsActive(ids, active);

      runInAction(() => {
        this.notifications
          .filter(({ id }) => ids.includes(id))
          .forEach((n) => (n.active = active));
      });

      this.home.setUIAlert("The changes have been applied");
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  setNotificationsQuery(query: string) {
    this.searchQuery = query;
  }

  setHighlightItem(id: NotificationsStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  private _updateNotification(data: UnifiedIncidentConfig) {
    const found = this.notificationsTable[data.id];
    if (!found) {
      this.notifications.push(data);
      return;
    }

    customMerge(found, data);
  }
}
